<template>
  <el-popover
    placement="top"
    v-model="visible"
    class="popconfirm--wrap">
    <p>{{ message }}</p>
    <div style="text-align: right; margin: 0">
      <el-button size="mini" type="text" @click="hide">{{ $t('commons.no') }}</el-button>
      <el-button type="primary" size="mini" @click="confirm">{{ $t('commons.yes') }}</el-button>
    </div>

    <el-button slot="reference" size="mini" type="danger">
      {{ $t('commons.delete') }}
    </el-button>

  </el-popover>
</template>
<script>
export default {
  name: 'ConfirmButton',
  data () {
    return {
      visible: false
    }
  },
  props: [
    'message'
  ],
  methods: {
    hide () {
      this.$set(this, 'visible', false)
    },
    confirm () {
      this.hide()
      this.$emit('confirm')
    }
  }
}
</script>
