<template>
  <el-row class="fanpage">
    <el-col :span="3" :offset="2">
      <router-link :to="{ name: 'FanpageNew' }" class="el-button el-button--success">
        <i class="el-icon-plus"></i>
        {{ $t('commons.new') }}
      </router-link>
    </el-col>
    <el-col :span="3" :offset="14">
      <el-select v-model="location" clearable>
        <el-option
          v-for="item in locations"
          :key="item.value"
          :label="item.text"
          :value="item.value">
        </el-option>
      </el-select>
    </el-col>

    <el-col :span="20" :offset="2" class="data">
      <el-table
        :data="items"
        stripe
        style="width: 100%"
        :empty-text="(items) ? $t('view.fanpage.index.empty') : $t('view.fanpage.index.loading')">
        <el-table-column
          prop="id"
          :label="$t('view.fanpage.index.id')"
          width="180">
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('view.fanpage.index.username')"
          width="500">
        </el-table-column>
        <el-table-column
          prop="priority"
          :label="$t('view.fanpage.index.priority')">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.priority ? 'success' : 'warning'"
              disable-transitions>{{ (scope.row.priority) ? 'Alta' : 'Baja'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="location"
          :label="$t('view.fanpage.index.location')">
        </el-table-column>
         <el-table-column
          :label="$t('commons.options')">
            <template slot-scope="scope">
              <el-button size="mini" @click="editMe(scope.$index)">
                {{ $t('commons.edit') }}
              </el-button>
              <confirm-button
                :message="$t('view.fanpage.index.confirm-delete-message')"
                @confirm="deleteMe(scope.$index)" />
            </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col align="center">
      <el-pagination
        @prev-click="loadData(location, +page - 1)"
        @next-click="loadData(location, +page + 1)"
        layout="prev, next" />
    </el-col>
  </el-row>
</template>
<script>
import EditorMixin from '@/mixins/editor'
import ConfirmButton from '@/components/confirm-button'

export default {
  name: 'FanpageList',
  data () {
    return {
      items: null,
      page: null,
      locations: [
        {
          text: this.$t('view.fanpage.index.all'),
          value: ''
        }
      ],
      location: ''
    }
  },
  watch: {
    async location (value) {
      try {
        this.$set(this, 'location', value)
        await this.loadData(value)
      } catch (error) {
        console.log(error)
        this.$message.error(this.$t('view.fanpage.filter-fail'))
      }
    }
  },
  methods: {
    editMe (index) {
      const vm = this
      const facebook = vm.items[index]

      vm.saveEditorData(facebook)
      vm.$router.push({
        name: 'FanpageEdit',
        params: {
          idFanpage: facebook.id
        }
      })
    },
    async deleteMe (index) {
      const vm = this
      const loading = vm.$loading({ lock: true })
      const fanpage = vm.items[index]

      try {
        await vm.$http.delete(`facebook/fanpage/${fanpage.id}`)
        await vm.loadData()
        loading.close()
      } catch (error) {
        console.log(error)
        loading.close()
        vm.$message.error(vm.$t('view.fanpage.index.delete-fail'))
      }
    },
    async loadCountries () {
      const vm = this

      try {
        const response = await vm.$http.get('settings/city/')
        const places = response.body.map(country => {
          return {
            text: country.name,
            value: country.name.toLowerCase()
          }
        })

        vm.$set(vm, 'locations', this.locations.concat(places))
      } catch (err) {
        console.log(err)
      }
    },
    async loadData (location = '', page = null) {
      const vm = this
      const url = `facebook/fanpage/${location}` + ((page) ? `?page=${page}` : '')

      if (page) {
        vm.$set(vm, 'page', page)
      } else {
        vm.$set(vm, 'page', 1)
      }

      try {
        vm.$set(vm, 'items', null)
        const response = await vm.$http.get(url)
        vm.$set(vm, 'items', response.data)
      } catch (error) {
        console.log(error)
        vm.$message.error(vm.$t('view.fanpage.index.loading-fail'))
      }
    }
  },
  mounted () {
    this.loadData()
    this.loadCountries()
  },
  components: {
    ConfirmButton
  },
  mixins: [
    EditorMixin
  ]
}
</script>
<style lang="sass">
.fanpage
  .data
    margin-top: 2rem
</style>
